export const alpha = {
    100: "FF",
    95: "F2",
    90: "E6",
    85: "D9",
    80: "CC",
    75: "BF",
    70: "B3",
    66: "A8",
    60: "99",
    50: "80",
    40: "66",
    33: "54",
    30: "4D",
    25: "40",
    20: "33",
    10: "1A",
    5: "0D",
    0: "00",
}

export const colors = {
    headers: {
        black: "#000000",
        lightBlack: "#272727",
        grey: "#000000".concat(alpha[50]),
        brandTop: "#E6E6E6",
    },

    links: {
        normal: "#272727".concat(alpha[50]),
        active: "#000000".concat(alpha[90]),
        activeCircle: "#000000".concat(alpha[75]),
    },

    paragraphs: {
        black: "#000000".concat(alpha[50]),
    },

    other: {
        lightBlack: "#272727",
        lightgrey: "#F5F5F5",
        white: "#ffffff",
        linkBlack: "#333333",
    },

    brands: {
        reklama: "#C7000A",
        drony: "#D4000B",
        biznes: "#8A0007",
    },

    button: {
        button1: '#ff00ff'
    }
}

export const fonts = {
    headerFont: "'Montserrat', sans-serif",
    paragraphFont: "'Raleway', sans-serif",
    footerFont: 'Ubuntu, sans-serif',
}

export const fontSizes = {
    fz0: {
        xs: 48,
        sm: 72,
        md: 128,
        lg: 128,
        xl: 200,
    },
    fz1: {
        xs: 36,
        sm: 48,
        md: 72,
        lg: 88,
        xl: 128,
    },
    fz2: {
        xs: 36,
        sm: 48,
        md: 48,
        lg: 48,
        xl: 48,
    },
    fz3: {
        xs: 18,
        sm: 24,
        md: 32,
        lg: 32,
        xl: 32,
    },
    fz4: {
        xs: 12,
        sm: 14,
        md: 14,
        lg: 16,
        xl: 16,
        hd: 16,

    },
    fz5: {
        xs: 12,
        sm: 14,
        md: 14,
        lg: 14,
        xl: 14,
    },
}

export const margins = {
    topBtmGradientHeight: {
        xs: 64,
        sm: 100,
        md: 150,
        lg: 140,
        xl: 140,
    },

    sectionsX: {
        // padding głównych sekcji po osi X
        xs: 24,
        sm: 36,
        md: 48,
        lg: 100,
        xl: 100,
    },
}

const xs = "@media screen and (max-width: 576px)"
const sm = "@media screen and (max-width: 992px)"
const md = "@media screen and (max-width: 1280px)"
const lg = "@media screen and (max-width: 1860px)"
const xl = "@media screen and (min-width: 1860.1px)"

export const breakpoint = {
    xs,
    sm,
    md,
    lg,
    xl,
}

// [breakpoint.lg]: {},
// [breakpoint.md]: {},
// [breakpoint.sm]: {},
// [breakpoint.xs]: {},