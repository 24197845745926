/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { fonts, colors, margins, breakpoint } from '../cms/style'

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <div css={stylesMain}>
      <div
        css={[styles.topSection, styles.section]}
      />
      {children}
      <div
        css={[styles.bottomSection, styles.section]}
      />
    </div>
  )
}

const stylesMain = {
  fontFamily: fonts.paragraphFont,
  "h1, h2, h3, h4, h5, h6": {
    fontFamily: fonts.headerFont
  },
  p: {
    color: colors.paragraphs.black,
    lineHeight: 1.6
  }
}

const styles = {
  section: {
    width: '100vw',
    position: 'fixed',
    left: '0',
    zIndex: 10,
    height: margins.topBtmGradientHeight.xl,

    [breakpoint.lg]: { height: margins.topBtmGradientHeight.lg, },
    [breakpoint.md]: { height: margins.topBtmGradientHeight.md, },
    [breakpoint.sm]: { height: margins.topBtmGradientHeight.sm, },
    [breakpoint.xs]: { height: margins.topBtmGradientHeight.xs, },
  },

  topSection: {
    backgroundImage: 'linear-gradient(to bottom, #f5efef, #ffffff00)',
    top: 0
  },
  bottomSection: {
    backgroundImage: 'linear-gradient(to top, #f5efef, #ffffff00)',
    bottom: 0
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
